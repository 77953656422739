<template>
	<div class="container">
		<div class="space-y-10 p-6 h-full">
			<div class="p-2 space-y-4 border-2 border-gray-300 bg-gray-100">
				<table>
					<tbody>
						<tr class="flex w-full">
							<td class="flex w-1/4 ml-6">
								<div>시험기간</div>
								<div class="ml-6">{{ this.$_commonlib.formatDate(initData.examStartDt, 'YYYY-MM-DDTHH:mm:ss', 'YYYY.MM.DD') }}</div>
							</td>
							<td class="flex w-1/4">
								<div>시험시간</div>
								<div class="ml-9">{{ formatTime(initData.examStartTime) }} ~ {{ formatTime(initData.examEndTime) }}</div>
							</td>
							<td class="flex w-1/4">
								<div>시험시간(분)</div>
								<div class="ml-6">{{ diffMinutes() }}</div>
							</td>
							<td class="flex w-1/4">
								<div>문제 수</div>
								<div class="ml-6">{{ initData.sheetRegCnt }}</div>
							</td>
						</tr>
						<tr class="flex w-full mt-4">
							<td class="flex w-1/4 ml-6">
								<div>응시자명</div>
								<div class="ml-6"></div>
							</td>
							<td class="flex w-1/4">
								<div>응시자 소속</div>
								<div class="ml-4"></div>
							</td>
							<td class="flex w-1/4">
								<div class="text-xl"></div>
								<div class="ml-12 text-xl text-red-500"></div>
							</td>
							<td class="flex w-1/4">
								<div></div>
								<div class="ml-4"></div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="flex space-x-4 h-4/5">
				<div class="w-3/5">
					<div class="text-2xl p-2">{{ initData.examNm }}</div>
					<div class="border p-4 space-y-2 h-5/6">
						<div class="flex gap-2 mb-4 relative">
							<div class="text-2xl">{{ questionData.questionOrd }}.</div>
							<div class="textSize" v-html="questionData.qustionNm"></div>
							<div class="text-xl ml-4 text-red-500 w-32">(배점 {{ questionData.maxScore }}점)</div>
							<div v-if="questionData.fileGroupId">
								<DxButton icon="download" hint="다운로드" @click="onDownload" />
							</div>
						</div>
						<!-- <div class="py-4">
							<img
								v-if="questionData.fileGroupId"
								class="object-contain w-full h-96"
								:src="$_getAttachFileURL(questionData.fileGroupId, questionData.fileName)"
							/>
						</div> -->
						<div class="h-1/2 relative overflow-y-auto">
							<div v-if="questionData.answerTypeCd == 996 || questionData.answerTypeCd == 997" class="flex pl-20 h-10">
								<span class="text-xl text-red-500">실 정답:</span>
								<div class="box-border h-10 w-2/3 border border-red-400 ml-4">
									<span class="text-xl  text-red-500 p-4">
										{{ questionData.questions[0].detailDescription }}
									</span>
								</div>
							</div>
							<div v-else v-for="(item1, index1) in questionData.questions" :key="index1" class="pl-20 h-6 mb-2">
								<span v-if="item1.score > 0">
									<img src="@/assets/images/ewm/right_answer.png" class="w-6 h-6 mb-2" />
								</span>
								<span v-else class="pl-6"></span>
								<span class="text-xl">{{ item1.questionNumber }}.</span>
								<span class="text-xl ml-4">{{ item1.detailDescription }}</span>
							</div>
						</div>
					</div>
					<div class="flex justify-center space-x-6 items-center pt-2">
						<div>
							<DxButton
								text="< 이전"
								styling-mode="outlined"
								class="btn_XS white light_filled"
								:disabled="questionData.questionOrd <= 1"
								@click="goPrev"
							/>
						</div>
						<div>{{ questionData.questionOrd }}/{{ initData.sheetRegCnt }}</div>
						<div>
							<DxButton
								text="다음 >"
								styling-mode="outlined"
								class="btn_XS white light_filled"
								:disabled="questionData.questionOrd >= initData.sheetRegCnt"
								@click="goNext"
							/>
						</div>
					</div>
				</div>

				<div class="w-2/5">
					<div class="flex text-2xl p-2 space-x-5">
						<div class="flex">
							<div class="mr-2">전체 문제</div>
							<div class="bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center">{{ initData.sheetRegCnt }}</div>
						</div>
						<div class="flex">
							<div class="mr-2">답변 완료</div>
							<div class="bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center">{{ initData.checkCnt }}</div>
						</div>
						<div class="flex">
							<div class="mr-2">답변 미완료</div>
							<div class="bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center">{{ initData.uncheckCnt }}</div>
						</div>
					</div>

					<div class="border h-5/6">
						<DxDataGrid
							key-expr="questionId"
							:data-source="formData"
							:show-borders="false"
							:show-column-lines="true"
							:show-row-lines="true"
							width="100%"
							height="100%"
							:selected-row-keys="questionSelectedRowKeys"
							@selection-changed="selectionChangedData"
						>
							<DxFilterRow :visible="false" />
							<DxSelection mode="single" />
							<DxScrolling mode="virtual" />
							<DxLoadPanel :enabled="true" />

							<DxColumn
								caption="문항순서"
								data-field="questionOrd"
								alignment="center"
								:allowFiltering="false"
								:visible="true"
								:width="70"
							/>
							<DxColumn caption="답안" data-field="answer" alignment="center" :allowFiltering="false" :visible="true" />
							<DxColumn
								caption="정답유형"
								data-field="answerTypeCd"
								alignment="center"
								:allowFiltering="false"
								:visible="true"
								:width="70"
							>
								<DxLookup :data-source="codes.answerTypeCd.dataSource" value-expr="codeId" display-expr="codeNm" />
							</DxColumn>
							<DxColumn
								caption="배점"
								data-field="maxScore"
								alignment="center"
								:allowFiltering="false"
								:visible="true"
								:width="60"
							/>
							<DxColumn caption="" cell-template="colorChangeTemplate" alignment="center" :visible="true" :width="40" />
							<template #colorChangeTemplate="{ data }">
								<div v-if="data.data.answer">
									<div class="w-4 h-4 bg-green-500"></div>
								</div>
								<div v-else>
									<div class="w-4 h-4 bg-gray-400"></div>
								</div>
							</template>
						</DxDataGrid>
					</div>
				</div>
			</div>

			<div class="bottom-btn-wrap">
				<DxButton text="닫기" :width="100" :height="40" class="btn_XS white light_filled" @click="closePreview" />
			</div>
		</div>
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxDataGrid, DxColumn, DxLookup, DxFilterRow, DxSelection, DxScrolling, DxLoadPanel } from 'devextreme-vue/data-grid';

export default {
	components: {
		DxButton,

		DxDataGrid,
		DxColumn,
		DxLookup,
		DxFilterRow,
		DxSelection,
		DxScrolling,
		DxLoadPanel,
	},
	props: {},
	watch: {},
	data() {
		return {
			questionSelectedRowKeys: [],
			loc: null,
			select: null,
			initData: [], //초기
			formData: [], //그리드
			questionData: [], //문항
			selectedRowIndex: 0,
			codes: {
				answerTypeCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
			},
			attachFileUrl: {
				fileUpload: 'COMMON_ATTACHED_FILE_UPLOAD',
				fileDownload: 'COMMON_ATTACHED_FILE_DOWNLOAD',
				fileDelete: 'COMMON_ATTACHED_FILE_DELETE',
			},
		};
	},
	computed: {},
	methods: {
		onDownload() {
			const fileGroupId = this.questionData.fileGroupId;
			const fileName = this.questionData.fileName;
			this.$_downloadAttachFile(fileGroupId, fileName);
			// const token = this.$store.getters.getAccessToken;

			// const downloadUrl = `${this.attachFileUrl.fileDownload}/${fileGroupId}/${fileName}`;

			// const requestOptions = {
			// 	method: 'GET',
			// 	headers: new Headers({
			// 		Authorization: 'Bearer ' + token,
			// 	}),
			// };

			// fetch(downloadUrl, requestOptions)
			// 	.then(response => {
			// 		if (!response.ok) {
			// 			this.$_Toast('다운로드 오류 발생');
			// 			throw new Error('response error');
			// 		}
			// 		return response.blob();
			// 	})
			// 	.then(blob => {
			// 		const url = window.URL.createObjectURL(blob);

			// 		// console.log('url', url);
			// 		const a = document.createElement('a');
			// 		a.href = url;
			// 		a.download = data.originalFileName;
			// 		document.body.appendChild(a);
			// 		a.click();
			// 		document.body.removeChild(a);
			// 		window.URL.revokeObjectURL(url);
			// 	})
			// 	.catch(error => {
			// 		// console.error('file download error:', error);
			// 		this.$_Toast('다운로드 오류 발생');
			// 	});
		},
		formatTime(time) {
			return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
		},
		diffMinutes() {
			const startTime = new Date();
			startTime.setHours(parseInt(this.initData.examStartTime.slice(0, 2), 10));
			startTime.setMinutes(parseInt(this.initData.examStartTime.slice(2, 4), 10));
			startTime.setSeconds(0);

			const endTime = new Date();
			endTime.setHours(parseInt(this.initData.examEndTime.slice(0, 2), 10));
			endTime.setMinutes(parseInt(this.initData.examEndTime.slice(2, 4), 10));
			endTime.setSeconds(0);

			const diffInMinutes = Math.floor((endTime - startTime) / (1000 * 60));
			return diffInMinutes;
		},
		/** @description : 이전문항 */
		goPrev() {
			if (this.selectedRowIndex == 0) {
				this.$_Msg('첫번째 문항입니다.');
			} else {
				--this.selectedRowIndex;
				this.questionData = this.formData[this.selectedRowIndex];

				this.questionSelectedRowKeys = [this.questionData.questionId];
			}
		},
		/** @description : 다음문항 */
		goNext() {
			if (this.selectedRowIndex < this.formData.length - 1) {
				this.selectedRowIndex++;
				this.questionData = this.formData[this.selectedRowIndex];

				this.questionSelectedRowKeys = [this.questionData.questionId];
			} else {
				this.$_Msg('마지막 문항입니다.');
			}
		},
		/** @description : 로우 클릭 이벤트 */
		selectionChangedData(e) {
			console.log('selectionChangedData', e.selectedRowKeys);
			this.questionSelectedRowKeys = e.selectedRowKeys;

			this.questionData = e.selectedRowsData[0];
			this.selectedRowIndex = this.questionData.questionOrd - 1;
		},
		/** @description : 닫기 */
		closePreview() {
			if (this.loc === 'status') {
				this.$router.push({ path: '/ewm/exam/implementation/exam-status', params: { select: this.select } });
			} else {
				this.$router.push({ path: '/ewm/exam/implementation/exam-schedule/list' });
			}
		},
		/** @description: 데이터 조회 메서드 */
		async selectFormData() {
			const payload = {
				actionname: 'EWM_EXAM_SHEET',
				path: '/' + this.initData.sheetId + '/questions',
				loading: false,
				useErrorPopup: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (res.status === 200) {
				if (res.data.header.resCode === 'success') {
					res.data.data.sort((a, b) => a.questionOrd - b.questionOrd);
					res.data.data.forEach(d => {
						d.questions.sort((s, c) => s.questionNumber - c.questionNumber);
					});

					this.formData = res.data.data;

					if (this.formData.length > 0) {
						this.questionData = this.formData[0];
						this.selectedRowIndex = this.questionData.questionOrd - 1;

						this.questionSelectedRowKeys = [this.questionData.questionId];
					}

					this.initData.sheetRegCnt = this.formData.length;
					this.initData.uncheckCnt = this.formData.filter(item => !item.answer).length;
					this.initData.checkCnt = this.formData.filter(item => item.answer).length;
				}
			}
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_edu_exam_answer_type');
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			this.reqParams = this.$store.getters.getDetailParams;
			this.initData = this.reqParams.data;
			this.loc = this.reqParams.loc;
			this.select = this.reqParams.select ? this.reqParams.select : null;

			this.initCodeMap().then(() => {
				this.codes.answerTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_answer_type'], 2);
			});

			this.selectFormData();
		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountData() {
			const apiFileUpload = this.$store.getters.getRoutingInfos.find(d => d.actionNm === 'COMMON_ATTACHED_FILE_UPLOAD');
			const apiFileDownload = this.$store.getters.getRoutingInfos.find(d => d.actionNm === 'COMMON_ATTACHED_FILE_DOWNLOAD');
			const apiFileDelete = this.$store.getters.getRoutingInfos.find(d => d.actionNm === 'COMMON_ATTACHED_FILE_DELETE');

			this.attachFileUrl.fileUpload = apiFileUpload.host + apiFileUpload.path;
			this.attachFileUrl.fileDownload = apiFileDownload.host + apiFileDownload.path;
			this.attachFileUrl.fileDelete = apiFileDelete.host + apiFileDelete.path;
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
};
</script>

<style scoped>
.textSize {
	margin-left: 0.5em;
	font-size: 1.5rem;
	line-height: 2rem;
	max-width: 100%;
	max-height: 100%;
}

.textSize::v-deep * {
	font-size: 1.5rem;
	line-height: 2rem;
	max-width: 100%;
	max-height: 100%;
}
</style>
